<template>
  <div class="about-us">
    <v-container fluid>
      <v-card elevation="0" class="pt-4 pb-16 mx-lg-16" min-height="700px">
        <v-row>
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="12"
            class="bg-grey-lighten-4 d-flex align-center justify-center"
          >
            <img src="../assets/about-us.png" height="550px" width="100%" />
          </v-col>
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="12"
            class="bg-grey-lighten-4 d-flex align-center justify-center"
          >
            <v-card elevation="0" class="px-lg-16 mx-lg-4 bg-transparent">
              <v-card-title class="py-5 px-5">{{
                $t("our_history")
              }}</v-card-title>
              <v-card-text class="px-5 pb-5">
                {{ $t("history_content") }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="py-10 px-5">
          <v-col cols="12" lg="8" md="8" sm="12">
            <h1 class="py-4">{{ $t("our_vision") }}</h1>
            <p>{{ $t("vision_content") }}</p>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="4"
            sm="12"
            class="d-flex align-center justify-center"
          >
            <v-btn
              class="px-14 py-6 mt-16 d-flex align-center justify-center"
              variant="outlined"
              color="green"
              density="default"
              @click="$router.push({ name: 'contact-us' })"
              >{{ $t("get_in_touch") }}</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="py-10">
          <v-col cols="12" lg="12" md="12" sm="12">
            <h1 class="py-4 text-center">{{ $t("our_achievements") }}</h1>
            <p class="px-16 text-center">
              {{ $t("achievements_content") }}
            </p>
          </v-col>
          <v-row class="mt-8">
            <v-col cols="3" class="d-flex align-center justify-center pt-5">
              <img src="../assets/about-1.svg" height="100px" />
            </v-col>
            <v-col cols="3" class="d-flex align-center justify-center pt-5">
              <img src="../assets/about-2.svg" height="100px" />
            </v-col>
            <v-col cols="3" class="d-flex align-center justify-center pt-5">
              <img src="../assets/about-3.svg" height="100px" />
            </v-col>
            <v-col cols="3" class="d-flex align-center justify-center pt-5">
              <img src="../assets/about-4.svg" height="100px" />
            </v-col>
          </v-row>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>
<style scoped>
.cover-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
</style>
