<template>
  <v-row>
    <v-col cols="12">
      <div class="d-flex flex-column align-center justify-center py-4">
        <h1 class="font-weight-thin">{{ $t("shop_by_category") }}</h1>
        <p>{{ $t("brilliant_design") }}</p>
      </div>
    </v-col>
    <v-col cols="12" class="mb-10">
      <v-row class="py-10">
        <v-col cols="6" md="4" lg="2" sm="6" class="px-4 py-6">
          <img src="@/assets/product-1.png" width="100%" height="80%" />
          <p class="text-center">{{ $t("perfumes") }}</p>
        </v-col>
        <v-col cols="6" md="4" lg="2" sm="6" class="px-4 py-6">
          <img src="@/assets/product-2.png" width="100%" height="80%" />
          <p class="text-center">{{ $t("beauty") }}</p>
        </v-col>
        <v-col cols="6" md="4" lg="2" sm="6" class="px-4 py-6">
          <img src="@/assets/product-3.png" width="100%" height="80%" />
          <p class="text-center">{{ $t("dokhoon") }}</p>
        </v-col>
        <v-col cols="6" md="4" lg="2" sm="6" class="px-4 py-6">
          <img src="@/assets/product-4.png" width="100%" height="80%" />
          <p class="text-center">{{ $t("oud") }}</p>
        </v-col>
        <v-col cols="6" md="4" lg="2" sm="6" class="px-4 py-6">
          <img src="@/assets/product-5.png" width="100%" height="80%" />
          <p class="text-center">{{ $t("spray") }}</p>
        </v-col>
        <v-col cols="6" md="4" lg="2" sm="6" class="px-4 py-6">
          <img src="@/assets/product-6.png" width="100%" height="80%" />
          <p class="text-center">{{ $t("oils") }}</p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
