import { createStore } from "vuex";
import categories from "./categories";
import cart from "./cart";
import wishList from "./wishList";
import products from "./products";

export default createStore({
  modules: {
    categories,
    cart,
    wishList,
    products,
  },
  state: {
    locale: "EN", // Ensure this is properly defined
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale;
    },
  },
  actions: {
    changeLocale({ commit }, locale) {
      commit("setLocale", locale);
    },
  },
});
