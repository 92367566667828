<template>
  <div class="cart-drawer">
    <v-navigation-drawer
      v-model="drawer"
      :width="windowWidth <= 1024 ? 1024 : '500'"
      class="pt-3"
      temporary
      location="right"
    >
      <v-row
        class="position-fixed Z-1050 w-100 d-flex align-center justify-center bg-white py-6 top-0 border-b-thin"
      >
        <v-col cols="11" class="d-flex align-center justify-center">
          <h1>SHOPPING BAG</h1>
        </v-col>
        <v-col cols="1">
          <v-icon @click="drawer = false"> mdi-close</v-icon>
        </v-col>
      </v-row>

      <v-card
        elevation="0"
        class="px-0 mt-16 scroll-inside-items item-card"
        max-height="710"
        style="overflow-y: auto"
      >
        <v-container class="px-1">
          <transition-group name="slide" tag="div">
            <v-row
              v-for="item in cartItems"
              :key="item.id"
              class="mx-0 align-center"
            >
              <v-col cols="12" lg="6" sm="5">
                <img
                  :src="item.images[0].src"
                  class="w-100"
                  style="height: 220px"
                />
              </v-col>
              <v-col cols="12" lg="6" sm="7" class="px-3 py-3">
                <v-card-title
                  class="px-0"
                  style="
                    white-space: pre-wrap;
                    font-size: 14px;
                    line-height: 1.2;
                  "
                  >{{ item.name }}</v-card-title
                >
                <v-card-text class="px-0 py-3" style="font-weight: 800">
                  ${{ Math.ceil(item.price * item.quantity) }}
                </v-card-text>

                <div class="d-flex justify-space-between align-center">
                  <div
                    style="
                      width: fit-content;
                      border: 2px solid;
                      border-radius: 30px;
                    "
                    class="counter px-1"
                  >
                    <v-icon
                      size="15"
                      @click="item.quantity > 1 ? item.quantity-- : false"
                      >mdi-minus</v-icon
                    >
                    <input
                      type="number"
                      style="
                        border: none;
                        outline: none;
                        width: 40px;
                        font-size: 13px;
                      "
                      class="text-center py-2"
                      min="1"
                      v-model="item.quantity"
                    />
                    <v-icon size="15" @click="item.quantity++">mdi-plus</v-icon>
                  </div>
                  <v-icon size="22" @click="deleteItem(item.id)"
                    >mdi-close</v-icon
                  >
                </div>
              </v-col>
            </v-row>
          </transition-group>
        </v-container>
      </v-card>

      <v-row class="w-100 bg-white" style="position: absolute; bottom: 0">
        <v-col cols="6" class="px-4 py-2 d-flex align-center justify-center">
          <v-btn
            class="px-16"
            color="red"
            text=" Check Out "
            variant="outlined"
            @click="$router.push({ name: 'check-out' })"
          />
        </v-col>
        <v-col cols="6" class="px-4 py-2 d-flex align-center justify-center">
          <v-btn
            class="px-16"
            color="orange"
            text=" View Cart "
            variant="flat"
            @click="$router.push({ name: 'view-cart' })"
          />
        </v-col>
      </v-row>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  inject: ["Emitter"],
  props: {
    windowWidth: {
      type: Number,
    },
  },
  computed: {
    ...mapState({
      cartItems: (state) => state.cart.cartItems,
    }),
    calcTotalPrice() {
      let total = 0;
      this.cartItems.forEach((product) => {
        total +=
          Math.ceil(
            product.price - product.price * (product.discountPercentage / 100)
          ) * product.quantity;
      });
      return total;
    },
  },
  methods: {
    ...mapActions(["getCartItem", "deleteItem"]),
  },
  data() {
    return {
      drawer: false,
      quantity: 1,
    };
  },
  mounted() {
    this.Emitter.on("openCart", () => {
      this.drawer = true;
    });
    this.getCartItem();
  },
};
</script>

<style lang="scss" scoped>
.Z-1050 {
  z-index: 1050 !important;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 1s ease;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
@media (max-width: 821px) {
  .cart-drawer {
    .item-card {
      max-height: 1100px !important;
    }
  }
}
@media (max-width: 400px) {
  .cart-drawer {
    .item-card {
      max-height: 700px !important;
    }
  }
}
</style>
