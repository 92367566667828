<template>
  <div class="nav-bar">
    <div class="app-nav-bar">
      <v-container fluid>
        <v-row>
          <v-col cols="4" class="d-flex align-center">
            <v-app-bar-nav-icon @click="openMenue"></v-app-bar-nav-icon>
            <v-icon class="ml-5" style="color: black; cursor: pointer" size="35"
              >mdi-magnify</v-icon
            >
          </v-col>
          <v-col cols="4" class="d-flex align-center">
            <img
              @click="$router.push('/')"
              src="@/assets/logo.jpg"
              style="cursor: pointer; width: 100%; height: 80%"
            />
          </v-col>
          <v-col cols="4" class="d-flex justify-end aline-center py-8 px-3">
            <v-icon class="mx-2" size="30" @click="openCart"
              >mdi-shopping-outline
            </v-icon>
            <v-icon class="mx-2" size="30" @click="$router.push('/contact-us')"
              >mdi-map-marker-outline
            </v-icon>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
// import { productsModule } from "@/store/index.js";
// import { mapState } from "pinia";
// import { CartStore } from "@/store/Cart.js";

export default {
  inject: ["Emitter"],
  // computed: {
  //   ...mapState(productsModule, ["singleProducts"]),
  //   ...mapState(CartStore, ["cartItems"]),
  // },
  methods: {
    openCart() {
      this.Emitter.emit("openCart");
    },
    openMenue() {
      this.Emitter.emit("openMenue");
    },
  },
};
</script>
<style scoped>
.app-nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px !important;
  display: flex;
  align-items: center;
  background-color: white;
  z-index: 1009;
}
</style>
