<template>
  <div ref="container"></div>
</template>

<script>
import * as THREE from "three";

export default {
  props: {
    imgUrls: {
      type: Array,
      required: true,
      validator: (value) => value.length === 6, // Ensure exactly 6 URLs are provided
    },
    postionAxis: {
      type: Array,
      required: true,
      validator: (value) => value.length === 3, // Ensure exactly 6 URLs are provided
    },
    SquerWidth: {
      type: Number,
    },
    SquerSize: {
      type: Array,
      required: true,
      validator: (value) => value.length === 2, // Ensure exactly 6 URLs are provided
    },
  },

  mounted() {
    this.init();
  },
  methods: {
    init() {
      // Get the container element
      const container = this.$refs.container;

      if (!container) {
        console.error("Container element not found");
        return;
      }

      // Create a scene
      const scene = new THREE.Scene();

      // Load the image textures
      const textureLoader = new THREE.TextureLoader();
      const textures = this.imgUrls.map((url) => {
        const texture = textureLoader.load(url);
        texture.wrapS = THREE.ClampToEdgeWrapping; // Prevent texture from repeating horizontally
        texture.wrapT = THREE.ClampToEdgeWrapping; // Prevent texture from repeating vertically
        texture.repeat.set(1, 1); // Ensure texture is not repeated
        texture.minFilter = THREE.LinearFilter; // Ensure texture is not pixelated when scaled
        return texture;
      });

      // Create materials with textures
      const materials = textures.map(
        (texture) => new THREE.MeshBasicMaterial({ map: texture })
      );

      // Create geometry (e.g., a box)
      const geometry = new THREE.BoxGeometry(
        this.SquerWidth,
        this.SquerWidth,
        this.SquerWidth
      );

      // Create the mesh with geometry and materials
      const cube = new THREE.Mesh(geometry, materials);

      // Add the mesh to the scene
      scene.add(cube);

      // Create a camera
      const camera = new THREE.PerspectiveCamera(1000, 100 / 100, 0.1, 1000);
      camera.position.z = 5;

      // Create a renderer
      const renderer = new THREE.WebGLRenderer();
      renderer.setSize(this.SquerSize[0], this.SquerSize[1]);
      renderer.setClearColor(0x000000, 0);
      // Append renderer's DOM element to the container
      container.appendChild(renderer.domElement);

      // Define animate function to render scene
      const animate = () => {
        requestAnimationFrame(animate);
        cube.rotation.x += this.postionAxis[0];
        cube.rotation.y += this.postionAxis[1];
        cube.rotation.z += this.postionAxis[2];

        renderer.render(scene, camera);
      };
      animate();
    },
  },
};
</script>

<style>
/* Add any necessary styles here */
</style>
