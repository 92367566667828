import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ContactUs from "../views/ContactUs.vue";
import AboutUs from "../views/AboutUs.vue";
// import PaymentPage from "../views/PaymentPage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: ContactUs,
  },
  {
    path: "/about-us",
    name: "about-us",
    component: AboutUs,
  },
  {
    path: "/products",
    name: "products",
    component: () => import("@/views/ProductsCategory.vue"),
  },
  {
    path: "/products/ProductDetails",
    name: "product-details",
    component: () => import("@/views/ProductDetails.vue"),
  },
  {
    path: "/ViewCart",
    name: "view-cart",
    component: () => import("@/views/ViewCart.vue"),
  },
  {
    path: "/WishList",
    name: "wish-list",
    component: () => import("@/views/WishList.vue"),
  },
  {
    path: "/CheckOut",
    name: "check-out",
    component: () => import("@/views/CheckOut.vue"),
  },
  {
    path: "/:NotFound(.*)",
    name: "not-found",
    component: () => import("@/views/NotFound.vue"),
  },
  {
    path: "/ConnectionError",
    name: "connection-error",
    component: () => import("@/views/ConnectionError.vue"),
  },
  {
    path: "/PaymentPage",
    name: "PaymentPage",
    component: () => import("@/views/PaymentPage.vue"),
  },

  // {
  //   path: "/products/CartPage",
  //   name: "cart-page",
  //   component: () => import("@/views/CartPage.vue"),
  // },
  // {
  //   path: "/CheckoutPage",
  //   name: "checkout-page",
  //   component: () => import("@/views/CheckoutPage.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
