import apiService from "../WooCommerceAPI";
const state = {
  products: [],
  currentPage: 1,
  totalPages: 0,
  loading: false,
  error: null,
  locale: "en", // Default language
};
const getters = {
  allProducts: (state) => state.products,
  currentPage: (state) => state.currentPage,
  totalPages: (state) => state.totalPages,
  isLoading: (state) => state.loading,
  hasError: (state) => state.error,
  currentLocale: (state) => state.locale,
};
const actions = {
  async fetchAllProducts({ commit, state }, { perPage, page }) {
    commit("setLoading", true);
    commit("setError", null);
    try {
      const response = await apiService.getAllProducts(
        perPage,
        page,
        state.locale
      );
      commit("setProducts", response.data);
      commit(
        "setTotalPages",
        parseInt(response.headers["x-wp-totalpages"], 10)
      );
      commit("setCurrentPage", page);
    } catch (error) {
      commit("setError", error);
    } finally {
      commit("setLoading", false);
    }
  },

  async setLocale({ commit, dispatch }, { locale }) {
    commit("setLocale", locale);
    // Refetch products when locale changes
    dispatch("fetchAllProducts", { perPage: 10, page: 1 });
  },
};

const mutations = {
  setProducts(state, products) {
    console.log("setProducts mutation called");
    console.log("Mutating products state with:", products);
    state.products = products;
  },
  setTotalPages(state, totalPages) {
    console.log("setTotalPages mutation called");
    console.log("Mutating totalPages state with:", totalPages);
    state.totalPages = totalPages;
  },
  setCurrentPage(state, currentPage) {
    console.log("setCurrentPage mutation called");
    console.log("Mutating currentPage state with:", currentPage);
    state.currentPage = currentPage;
  },
  setLoading(state, loading) {
    console.log("setLoading mutation called");
    console.log("Mutating loading state with:", loading);
    state.loading = loading;
  },
  setError(state, error) {
    console.log("setError mutation called");
    console.log("Mutating error state with:", error);
    state.error = error;
  },
  setLocale(state, locale) {
    console.log("setLocale mutation called");
    console.log("Mutating locale state with:", locale);
    state.locale = locale;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
