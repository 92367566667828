import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import { createI18n } from "vue-i18n";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";
import AR from "./plugins/Ar.json";
import EN from "./plugins/En.json";
import mitt from "mitt";

const app = createApp(App);

const Emitter = mitt();

const vuetify = createVuetify({
  components,
  directives,
});
app.use(store);

const i18n = createI18n({
  locale: store.state.locale,
  messages: {
    EN: EN,
    AR: AR,
  },
});

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

app.use(vuetify);
app.provide("Emitter", Emitter);
app.use(router);
app.use(i18n);
app.mount("#app");
