const categories = {
  PERFUMES: {
    Perfumes: {},
    "Mini Perfumes": {},
  },
  BEAUTY: {
    "Carmen Beauty": {},
    "Hair Mist": {},
  },
  DOKHOON: {
    "Carmen Dokhoon": {},
  },
  OUD: {
    "Carmen Oud Wood": {},
  },
  SPRAY: {
    "Home Spray": {},
    "All Over Spray": {},
  },
  OILS: {
    "Carmen Oils": {},
    "Dehn Al Oud": {},
  },
  BOXES: {
    "Set Boxes": {},
    "Gift Boxes": {},
    "Indian Agarwood Boxes": {},
  },
  ACCESSORIES: {
    "Carmen Accessories": {},
  },
  GIVEAWAYS: {
    "Give Away": {},
    "Wedding Services": {},
  },
};

export default categories;
