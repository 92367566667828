<template>
  <div class="Menu-drawer">
    <v-navigation-drawer
      v-model="drawer"
      :width="1024"
      class="pt-12"
      temporary
      location="left"
    >
      <div v-show="drawerProductDetails" class="navigation-drawer-third pt-4">
        <v-row
          class="position-fixed Z-1050 w-100 d-flex align-center justify-center bg-white py-3 top-0"
        >
          <v-col cols="11" class="d-flex align-center justify-center">
            <img src="../assets/logo.jpg" height="49px" width="160px" />
          </v-col>
          <v-col cols="1">
            <v-icon @click="closeDrawer"> mdi-close</v-icon>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="px-3 py-10">
            <v-row class="py-6 px-3 cursor-pointer">
              <v-col
                cols="12"
                class="d-flex align-center justify-start font-weight-black text-red-darken-4"
                @click="drawerProductDetails = false"
              >
                <v-icon class="ml-5">mdi-chevron-left</v-icon>
                {{ titleProductDetails }}
              </v-col>
            </v-row>
            <row class="d-flex align-center justify-space-between flex-wrap">
              <v-col
                cols="6"
                class="my-3"
                v-for="(index, product) in productDetail"
                :key="product"
                @click="makeRouteLink"
              >
                <img
                  :src="index.images.map((url) => url.src)"
                  width="95%"
                  height="250px"
                  class="align-center"
                />
                <p class="text-center">{{ index.name }}</p>
              </v-col>
              <v-col cols="12">
                <div class="d-flex justify-center align-center pt-1">
                  <button
                    @click="makeRouteLink"
                    class="underline-hover my-4 text-teal"
                    style="text-decoration: none"
                  >
                    View All
                  </button>
                </div>
              </v-col>
            </row>
          </v-col>
        </v-row>
      </div>

      <div v-show="drawerSubtitle" class="navigation-drawer-second pt-12">
        <v-row
          class="position-fixed Z-1050 w-100 d-flex align-center justify-center bg-white py-3 top-0"
        >
          <v-col cols="11" class="d-flex align-center justify-center">
            <img src="../assets/logo.jpg" height="49px" width="160px" />
          </v-col>
          <v-col cols="1">
            <v-icon @click="closeDrawer"> mdi-close</v-icon>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="px-3 py-10">
            <v-row class="py-6 px-3 cursor-pointer">
              <v-col
                cols="12"
                class="d-flex align-center justify-start font-weight-black text-red-darken-4"
                @click="drawerSubtitle = false"
              >
                <v-icon class="ml-5">mdi-chevron-left</v-icon>
                {{ titleSubCategories }}
              </v-col>
            </v-row>
            <v-row
              v-for="(index, subCategory) in subCategories"
              :key="subCategory"
              class="py-6 px-3 cursor"
              @click="productDetails(index, subCategory)"
            >
              <v-col cols="11" class="d-flex align-center justify-start">
                {{ subCategory }}
              </v-col>
              <v-col cols="1" class="d-flex align-center justify-end">
                <v-icon>mdi-chevron-right</v-icon></v-col
              >
            </v-row>
          </v-col>
        </v-row>
      </div>

      <v-row
        class="position-fixed Z-1050 w-100 d-flex align-center justify-center bg-white py-3 top-0"
      >
        <v-col cols="11" class="d-flex align-center justify-center">
          <img src="../assets/logo.jpg" height="49px" width="160px" />
        </v-col>
        <v-col cols="1">
          <v-icon @click="drawer = false"> mdi-close</v-icon>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="px-3 py-10">
          <v-row
            v-for="(index, category) in categories"
            :key="category"
            class="py-6 px-3 cursor"
            @click="showSubtitle(index, category)"
          >
            <v-col cols="11" class="d-flex align-center justify-start">
              {{ category }}
            </v-col>
            <v-col cols="1" class="d-flex align-center justify-end">
              <v-icon>mdi-chevron-right</v-icon></v-col
            >
          </v-row>
        </v-col>
        <v-footer class="navigation-footer bg-red-darken-4 w-100">
          <v-row justify="center" no-gutters>
            <v-col
              class="text-center pt-4 d-flex align-start justify-start flex-column"
              cols="12"
            >
              <h6
                class="py-3 px-12"
                @click="$router.push({ name: 'contact-us' })"
              >
                <span class="mr-5"> <v-icon>mdi-headphones</v-icon> </span
                >CONTACT US
              </h6>
              <h6
                class="py-3 px-12"
                @click="$router.push({ name: 'about-us' })"
              >
                <span class="mr-5">
                  <v-icon>mdi-room-service-outline</v-icon> </span
                >ABOUT US
              </h6>
              <h6
                class="py-3 px-12"
                @click="$router.push({ name: 'wish-list' })"
              >
                <span class="mr-5"> <v-icon>mdi-heart-outline</v-icon> </span
                >WISH LIST
              </h6>
            </v-col>
          </v-row>
        </v-footer>
      </v-row>
    </v-navigation-drawer>
  </div>
</template>

<script>
import categories from "../static-data/categories.js";
import { simpleEncrypt } from "@/utils/encryption";
export default {
  inject: ["Emitter"],
  data() {
    return {
      categories: categories,
      subCategories: [],
      productDetail: [],
      titleSubCategories: "",
      titleProductDetails: "",
      drawer: false,
      drawerSubtitle: false,
      drawerProductDetails: false,
      routerName: "",
      routerId: "",
    };
  },
  methods: {
    showSubtitle(subCategories, titleSubCategories) {
      this.drawerSubtitle = true;
      this.subCategories = subCategories;
      this.titleSubCategories = titleSubCategories;
    },
    productDetails(productDetail, titleProductDetails) {
      this.drawerProductDetails = true;
      this.titleProductDetails = titleProductDetails;
      this.productDetail = productDetail;
      this.routerName = this.productDetail[0].categories[0].name;
      this.routerId = this.productDetail[0].categories[0].id;
      localStorage.setItem("routerName", this.routerName);
      localStorage.setItem("routerId", this.routerId);
    },
    closeDrawer() {
      this.drawer = false;
      this.drawerSubtitle = false;
      this.drawerProductDetails = false;
    },
    makeRouteLink() {
      const routerlinkName = localStorage.getItem("routerName");
      const routerlinkId = localStorage.getItem("routerId");
      const encryptedrouterlinkId = simpleEncrypt(routerlinkId.toString());
      const encryptedrouterlinkName = simpleEncrypt(routerlinkName);
      this.$router.push({
        path: "/products",
        query: {
          id: encryptedrouterlinkId,
          category: encryptedrouterlinkName,
        },
      });
      this.closeDrawer();
    },
  },

  mounted() {
    this.Emitter.on("openMenue", () => {
      this.drawer = !this.drawer;
    });
  },
};
</script>

<style lang="scss" scoped>
.Z-1050 {
  z-index: 1050 !important;
}
.navigation-drawer-second {
  z-index: 1051 !important;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.navigation-drawer-third {
  z-index: 1052 !important;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
}
</style>
