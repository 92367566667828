import apiService from "../WooCommerceAPI";
import router from "@/router";

export default {
  state: {
    miniPerfumes: [],
    miniPerfumesAr: [],
    Perfumes: [],
    PerfumesAr: [],
    CarmenBeauty: [],
    CarmenBeautyAr: [],
    HairMist: [],
    HairMistAr: [],
    Dokhoon: [],
    DokhoonAr: [],
    OudWood: [],
    OudWoodAr: [],
    HomeSpray: [],
    HomeSprayAr: [],
    Alloverspray: [],
    AlloversprayAr: [],
    Oils: [],
    OilsAr: [],
    DehnAlOud: [],
    DehnAlOudAr: [],
    SetBoxes: [],
    SetBoxesAr: [],
    GiftBoxes: [],
    GiftBoxesAr: [],
    IndianAgarwoodBoxes: [],
    IndianAgarwoodBoxesAr: [],
    Accessories: [],
    AccessoriesAr: [],
    Giveaways: [],
    GiveawaysAr: [],
    WeddingServices: [],
    WeddingServicesAr: [],
  },
  getters: {
    miniPerfumes: (state, getters, rootState) => {
      const locale = rootState.i18n.locale;
      return locale === "ar" ? state.miniPerfumesAr : state.miniPerfumes;
    },
    Perfumes: (state, getters, rootState) => {
      const locale = rootState.i18n.locale;
      return locale === "ar" ? state.PerfumesAr : state.Perfumes;
    },
    CarmenBeauty: (state, getters, rootState) => {
      const locale = rootState.i18n.locale;
      return locale === "ar" ? state.CarmenBeautyAr : state.CarmenBeauty;
    },
    HairMist: (state, getters, rootState) => {
      const locale = rootState.i18n.locale;
      return locale === "ar" ? state.HairMistAr : state.HairMist;
    },
    Dokhoon: (state, getters, rootState) => {
      const locale = rootState.i18n.locale;
      return locale === "ar" ? state.DokhoonAr : state.Dokhoon;
    },
    OudWood: (state, getters, rootState) => {
      const locale = rootState.i18n.locale;
      return locale === "ar" ? state.OudWoodAr : state.OudWood;
    },
    HomeSpray: (state, getters, rootState) => {
      const locale = rootState.i18n.locale;
      return locale === "ar" ? state.HomeSprayAr : state.HomeSpray;
    },
    Alloverspray: (state, getters, rootState) => {
      const locale = rootState.i18n.locale;
      return locale === "ar" ? state.AlloversprayAr : state.Alloverspray;
    },
    Oils: (state, getters, rootState) => {
      const locale = rootState.i18n.locale;
      return locale === "ar" ? state.OilsAr : state.Oils;
    },
    DehnAlOud: (state, getters, rootState) => {
      const locale = rootState.i18n.locale;
      return locale === "ar" ? state.DehnAlOudAr : state.DehnAlOud;
    },
    SetBoxes: (state, getters, rootState) => {
      const locale = rootState.i18n.locale;
      return locale === "ar" ? state.SetBoxesAr : state.SetBoxes;
    },
    GiftBoxes: (state, getters, rootState) => {
      const locale = rootState.i18n.locale;
      return locale === "ar" ? state.GiftBoxesAr : state.GiftBoxes;
    },
    IndianAgarwoodBoxes: (state, getters, rootState) => {
      const locale = rootState.i18n.locale;
      return locale === "ar"
        ? state.IndianAgarwoodBoxesAr
        : state.IndianAgarwoodBoxes;
    },
    Accessories: (state, getters, rootState) => {
      const locale = rootState.i18n.locale;
      return locale === "ar" ? state.AccessoriesAr : state.Accessories;
    },
    Giveaways: (state, getters, rootState) => {
      const locale = rootState.i18n.locale;
      return locale === "ar" ? state.GiveawaysAr : state.Giveaways;
    },
    WeddingServices: (state, getters, rootState) => {
      const locale = rootState.i18n.locale;
      return locale === "ar" ? state.WeddingServicesAr : state.WeddingServices;
    },
  },
  mutations: {
    setMiniPerfumes(state, { en, ar }) {
      state.miniPerfumes = en;
      state.miniPerfumesAr = ar;
    },
    setPerfumes(state, { en, ar }) {
      state.Perfumes = en;
      state.PerfumesAr = ar;
    },
    setCarmenBeauty(state, { en, ar }) {
      state.CarmenBeauty = en;
      state.CarmenBeautyAr = ar;
    },
    setHairMist(state, { en, ar }) {
      state.HairMist = en;
      state.HairMistAr = ar;
    },
    setDokhoon(state, { en, ar }) {
      state.Dokhoon = en;
      state.DokhoonAr = ar;
    },
    setOudWood(state, { en, ar }) {
      state.OudWood = en;
      state.OudWoodAr = ar;
    },
    setHomeSpray(state, { en, ar }) {
      state.HomeSpray = en;
      state.HomeSprayAr = ar;
    },
    setAlloverspray(state, { en, ar }) {
      state.Alloverspray = en;
      state.AlloversprayAr = ar;
    },
    setOils(state, { en, ar }) {
      state.Oils = en;
      state.OilsAr = ar;
    },
    setDehnAlOud(state, { en, ar }) {
      state.DehnAlOud = en;
      state.DehnAlOudAr = ar;
    },
    setSetBoxes(state, { en, ar }) {
      state.SetBoxes = en;
      state.SetBoxesAr = ar;
    },
    setGiftBoxes(state, { en, ar }) {
      state.GiftBoxes = en;
      state.GiftBoxesAr = ar;
    },
    setIndianAgarwoodBoxes(state, { en, ar }) {
      state.IndianAgarwoodBoxes = en;
      state.IndianAgarwoodBoxesAr = ar;
    },
    setAccessories(state, { en, ar }) {
      state.Accessories = en;
      state.AccessoriesAr = ar;
    },
    setGiveaways(state, { en, ar }) {
      state.Giveaways = en;
      state.GiveawaysAr = ar;
    },
    setWeddingServices(state, { en, ar }) {
      state.WeddingServices = en;
      state.WeddingServicesAr = ar;
    },
  },
  actions: {
    async fetchProducts({ commit }) {
      try {
        const [response1, response2] = await Promise.all([
          apiService.getAllProducts(60, 1),
          apiService.getAllProducts(60, 2),
        ]);

        const combinedData = [...response1.data, ...response2.data];

        const miniPerfumesEn = combinedData.filter((product) =>
          product.categories.some(
            (category) => category.name === "Mini Perfumes"
          )
        );

        const miniPerfumesAr = combinedData.filter((product) =>
          product.categories.some((category) => category.name === "عطور صغيرة")
        );

        const PerfumesEn = combinedData.filter((product) =>
          product.categories.some((category) => category.name === "Perfumes")
        );

        const PerfumesAr = combinedData.filter((product) =>
          product.categories.some((category) => category.name === "عطور")
        );

        const CarmenBeautyEn = combinedData.filter((product) =>
          product.categories.some(
            (category) => category.name === "Carmen Beauty"
          )
        );

        const CarmenBeautyAr = combinedData.filter((product) =>
          product.categories.some((category) => category.name === "جمال كارمن")
        );

        const HairMistEn = combinedData.filter((product) =>
          product.categories.some((category) => category.name === "Hair Mist")
        );

        const HairMistAr = combinedData.filter((product) =>
          product.categories.some((category) => category.name === "ضباب الشعر")
        );

        const DokhoonEn = combinedData.filter((product) =>
          product.categories.some((category) => category.name === "Dokhoon")
        );

        const DokhoonAr = combinedData.filter((product) =>
          product.categories.some((category) => category.name === "دخون")
        );

        const OudWoodEn = combinedData.filter((product) =>
          product.categories.some((category) => category.name === "Oud Wood")
        );

        const OudWoodAr = combinedData.filter((product) =>
          product.categories.some((category) => category.name === "عود كارمن")
        );

        const HomeSprayEn = combinedData.filter((product) =>
          product.categories.some((category) => category.name === "Home Spray")
        );

        const HomeSprayAr = combinedData.filter((product) =>
          product.categories.some((category) => category.name === "بخاخ المنزل")
        );

        const AlloversprayEn = combinedData.filter((product) =>
          product.categories.some(
            (category) => category.name === "All over spray"
          )
        );

        const AlloversprayAr = combinedData.filter((product) =>
          product.categories.some((category) => category.name === "بخاخ عام")
        );

        const OilsEn = combinedData.filter((product) =>
          product.categories.some((category) => category.name === "Oils")
        );

        const OilsAr = combinedData.filter((product) =>
          product.categories.some((category) => category.name === "زيوت")
        );

        const DehnAlOudEn = combinedData.filter((product) =>
          product.categories.some((category) => category.name === "Dehn Al Oud")
        );

        const DehnAlOudAr = combinedData.filter((product) =>
          product.categories.some((category) => category.name === "دهن العود")
        );

        const SetBoxesEn = combinedData.filter((product) =>
          product.categories.some((category) => category.name === "Set Boxes")
        );

        const SetBoxesAr = combinedData.filter((product) =>
          product.categories.some((category) => category.name === "صناديق")
        );

        const GiftBoxesEn = combinedData.filter((product) =>
          product.categories.some((category) => category.name === "Gift Boxes")
        );

        const GiftBoxesAr = combinedData.filter((product) =>
          product.categories.some(
            (category) => category.name === "صناديق الهدايا"
          )
        );

        const IndianAgarwoodBoxesEn = combinedData.filter((product) =>
          product.categories.some(
            (category) => category.name === "Indian Agarwood Boxes"
          )
        );

        const IndianAgarwoodBoxesAr = combinedData.filter((product) =>
          product.categories.some(
            (category) => category.name === "صناديق العود الهندي"
          )
        );

        const AccessoriesEn = combinedData.filter((product) =>
          product.categories.some((category) => category.name === "Accessories")
        );

        const AccessoriesAr = combinedData.filter((product) =>
          product.categories.some((category) => category.name === "اكسسوارات")
        );

        const GiveawaysEn = combinedData.filter((product) =>
          product.categories.some((category) => category.name === "Giveaways")
        );

        const GiveawaysAr = combinedData.filter((product) =>
          product.categories.some(
            (category) => category.name === "الهدايا الترويجية"
          )
        );

        const WeddingServicesEn = combinedData.filter((product) =>
          product.categories.some(
            (category) => category.name === "Wedding Services"
          )
        );

        const WeddingServicesAr = combinedData.filter((product) =>
          product.categories.some(
            (category) => category.name === "خدمات الزفاف"
          )
        );

        commit("setMiniPerfumes", { en: miniPerfumesEn, ar: miniPerfumesAr });
        commit("setPerfumes", { en: PerfumesEn, ar: PerfumesAr });
        commit("setCarmenBeauty", { en: CarmenBeautyEn, ar: CarmenBeautyAr });
        commit("setHairMist", { en: HairMistEn, ar: HairMistAr });
        commit("setDokhoon", { en: DokhoonEn, ar: DokhoonAr });
        commit("setOudWood", { en: OudWoodEn, ar: OudWoodAr });
        commit("setHomeSpray", { en: HomeSprayEn, ar: HomeSprayAr });
        commit("setAlloverspray", { en: AlloversprayEn, ar: AlloversprayAr });
        commit("setOils", { en: OilsEn, ar: OilsAr });
        commit("setDehnAlOud", { en: DehnAlOudEn, ar: DehnAlOudAr });
        commit("setSetBoxes", { en: SetBoxesEn, ar: SetBoxesAr });
        commit("setGiftBoxes", { en: GiftBoxesEn, ar: GiftBoxesAr });
        commit("setIndianAgarwoodBoxes", {
          en: IndianAgarwoodBoxesEn,
          ar: IndianAgarwoodBoxesAr,
        });
        commit("setAccessories", { en: AccessoriesEn, ar: AccessoriesAr });
        commit("setGiveaways", { en: GiveawaysEn, ar: GiveawaysAr });
        commit("setWeddingServices", {
          en: WeddingServicesEn,
          ar: WeddingServicesAr,
        });
      } catch (error) {
        console.error("Error fetching products:", error);
        if (
          error.message.includes("Network Error") ||
          error.code === "ERR_NETWORK"
        ) {
          router.push("/ConnectionError");
        } else {
          router.push("/not-found");
        }
      }
    },
  },
};
