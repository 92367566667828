<template>
  <v-row>
    <v-col cols="12">
      <div class="control-img mx-lg-16">
        <img
          src="@/assets/product4.png"
          alt="logo"
          width="100%"
          height="100%"
          class="img-hover"
        />
      </div>
      <div class="d-flex flex-column align-center justify-center py-6">
        <h2 class="py-2 mx-2 text-center">
          {{ $t("mothers_day_title") }}
        </h2>
        <p class="py-2 text-center">
          {{ $t("mothers_day_description") }}
        </p>
        <a class="underline-hover my-4 text-teal">{{ $t("shop_gift") }}</a>
      </div>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.control-img {
  height: 61vh;
  position: relative;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  margin: 0px 71.6px !important;
}
.img-hover {
  /* Styles for the image */
  transition: transform 0.3s ease; /* Example transition */
}

.img-hover:hover {
  /* Styles for the image on hover */
  transform: scale(1.1); /* Example transformation */
}
.underline-hover {
  position: relative;
  cursor: pointer;
  font-size: 0.9rem;
  color: #474747;
}
.underline-hover::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 1px;
  background-color: #000; /* Adjust color as needed */
  transition: width 0.3s ease-in-out; /* Adjust transition duration and timing function as needed */
}

.underline-hover:hover::after {
  width: 100%;
}
@media (max-width: 1024px) {
  .control-img {
    height: 40vh;
    margin: 0px 41.6px !important;
  }
}
@media (max-width: 600px) {
  .control-img {
    height: 30vh;
    margin: 0px 21.6px !important;
  }
}
</style>
