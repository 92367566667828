<template>
  <div class="fixed-nav-bar bg-white pt-3 px-8" @mouseleave="mouseleave()">
    <v-container fluid>
      <v-row class="mt-2 mx-16 pt-1 pb-6 border-b-thin">
        <v-col clos="12" class="mx-16" @mouseover="showProduct = flase">
          <ul class="d-flex justify-space-between" style="list-style: none">
            <li
              class="links"
              v-for="(details, category) in categories"
              :key="category"
              @mouseover="setActiveCategory(category)"
            >
              {{ category }}
            </li>
            <li>|<v-icon>mdi-magnify</v-icon></li>
          </ul>
        </v-col>
      </v-row>
      <v-row v-show="showDetails" class="mx-16 py-5">
        <v-col clos="12" class="mx-16">
          <div class="custom-card">
            <v-card-text> </v-card-text>
            <v-sheet
              class="mx-auto my-2 d-flex align-center justify-center"
              max-width="1200"
              v-if="activeCategory && categories[activeCategory]"
            >
              <v-slide-group show-arrows>
                <v-slide-group-item
                  v-for="(detail, deltialName) in categories[activeCategory]"
                  :key="detail"
                >
                  <a
                    class="links mx-4 my-2"
                    @mouseover="
                      showProductDetails(
                        detail,
                        detail[0].categories[0].name,
                        detail[0].categories[0].id
                      )
                    "
                  >
                    {{ deltialName }}
                  </a>
                </v-slide-group-item>
              </v-slide-group>
            </v-sheet>

            <v-sheet
              class="mx-auto my-4 d-flex align-center justify-center"
              elevation="0"
              max-width="1000"
            >
              <v-slide-group show-arrows>
                <v-slide-group-item
                  v-for="pro in activeProduct"
                  :key="pro.name"
                >
                  <div
                    class="d-flex flex-column align-center justify-space-between cursor-pointer"
                    @click="makeRouteLink"
                  >
                    <img
                      :src="pro.images.map((url) => url.src)"
                      alt="Product Image"
                      width="200"
                      height="250"
                      class="mx-2 my-2"
                    />
                    <a class="underline-hover my-2 mx-6">{{ pro.name }}</a>
                  </div>
                </v-slide-group-item>
              </v-slide-group>
            </v-sheet>
            <div class="d-flex justify-center align-center pt-1">
              <button
                @click="makeRouteLink"
                class="underline-hover my-4 text-teal"
                style="text-decoration: none"
              >
                View All
              </button>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <HoverBG v-if="showDetails" />
</template>

<script>
import theCategories from "../static-data/categories";
import HoverBG from "./UI/HoverBG.vue";
import { simpleEncrypt } from "@/utils/encryption";

export default {
  inject: ["Emitter"],
  components: {
    HoverBG,
  },
  data() {
    return {
      showDetails: false,
      showProduct: false,
      activeCategory: null,
      activeProduct: null,
      routerName: "",
      routerId: "",
      categories: {}, // Initialize as an empty object
    };
  },
  beforeMount() {
    this.updateCategories();
  },
  watch: {
    "$i18n.locale": "updateCategories",
  },
  methods: {
    updateCategories() {
      console.log("Updating categories...");
      this.categories =
        this.$i18n.locale === "EN" ? theCategories.en : theCategories.ar;
      console.log("Categories updated:", this.categories);
    },
    changeLocale(locale) {
      this.$i18n.locale = locale;
    },
    showProductDetails(detail) {
      this.showProduct = true;
      this.activeProduct = detail;
      if (
        detail &&
        detail[0] &&
        detail[0].categories &&
        detail[0].categories[0]
      ) {
        this.routerName = detail[0].categories[0].name;
        this.routerId = detail[0].categories[0].id;
        localStorage.setItem("routerName", this.routerName);
        localStorage.setItem("routerId", this.routerId);
      }
    },
    setActiveCategory(category) {
      console.log("Setting active category:", category);
      console.log("Current categories:", this.categories);

      if (!this.categories || !this.categories[category]) {
        console.error("Categories not defined or category not found");
        return;
      }

      this.showDetails = true;
      this.showProduct = true;
      this.activeCategory = category;
      const firstProduct = Object.values(this.categories[category])[0];

      if (firstProduct && firstProduct[0] && firstProduct[0].categories) {
        this.activeProduct = firstProduct;
        this.routerName = firstProduct[0].categories[0].name;
        this.routerId = firstProduct[0].categories[0].id;
        localStorage.setItem("routerName", this.routerName);
        localStorage.setItem("routerId", this.routerId);
      }
    },
    mouseleave() {
      this.showDetails = false;
      this.showProduct = false;
    },
    openCart() {
      this.Emitter.emit("openCart");
    },
    makeRouteLink() {
      const routerlinkName = localStorage.getItem("routerName");
      const routerlinkId = localStorage.getItem("routerId");
      if (routerlinkName && routerlinkId) {
        const encryptedrouterlinkId = simpleEncrypt(routerlinkId.toString());
        const encryptedrouterlinkName = simpleEncrypt(routerlinkName);
        this.$router.push({
          path: "/products",
          query: {
            id: encryptedrouterlinkId,
            category: encryptedrouterlinkName,
          },
        });
        this.showDetails = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.fixed-nav-bar {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1008;
}
.underline-hover {
  position: relative;
  cursor: pointer;
  font-size: 0.9rem;
  color: #474747;
}
.underline-hover::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 1px;
  background-color: #000; /* Adjust color as needed */
  transition: width 0.3s ease-in-out; /* Adjust transition duration and timing function as needed */
}

.underline-hover:hover::after {
  width: 100%;
}
.icon-hover:hover {
  color: red;
  cursor: pointer;
}

.links {
  position: relative;
  cursor: pointer;
  font-size: 0.9rem;
  color: rgb(103, 103, 103);
}
.links::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -7px;
  width: 0;
  height: 3px;
  background-color: #c10016; /* Adjust color as needed */
  transition: width 0.3s ease-in-out; /* Adjust transition duration and timing function as needed */
}
.links:hover {
  color: black;
}
.links:hover::after {
  width: 100%;
}
.custom-card {
  background-color: transparent !important;
  border: none !important;
}
</style>
