<template>
  <div class="hover-bg bg-grey-darken-1"></div>
</template>

<style lang="scss" scoped>
.hover-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.8;
  z-index: 1007 !important;
}
</style>
