export default {
  state: () => ({
    wishListItems: [],
  }),
  getters: {
    wishListItems: (state) => state.wishListItems,
  },
  mutations: {
    ADD_wishList_ITEM(state, item) {
      const exists = state.wishListItems.some(
        (wishListItem) => wishListItem.id === item.id
      );
      if (!exists) {
        state.wishListItems.push(JSON.parse(JSON.stringify(item)));
        localStorage.setItem(
          "wishList-item",
          JSON.stringify(state.wishListItems)
        );
      }
    },
    SET_wishList_ITEMS(state, items) {
      state.wishListItems = items;
    },
    DELETE_wishList_ITEM(state, id) {
      const index = state.wishListItems.findIndex((item) => item.id === id);
      if (index !== -1) {
        state.wishListItems.splice(index, 1);
        localStorage.setItem(
          "wishList-item",
          JSON.stringify(state.wishListItems)
        );
      }
    },
    RESET_wishList_ITEMS(state) {
      state.wishListItems = [];
      localStorage.removeItem("wishList-item");
    },
  },
  actions: {
    addWishListItem({ commit, state }, item) {
      const exists = state.wishListItems.some(
        (wishListItem) => wishListItem.id === item.id
      );
      if (!exists) {
        commit("ADD_wishList_ITEM", item);
      }
    },
    getwishListItem({ commit }) {
      const items = localStorage.getItem("wishList-item");
      if (items) {
        commit("SET_wishList_ITEMS", JSON.parse(items));
      }
    },
    deletewishListItem({ commit }, id) {
      commit("DELETE_wishList_ITEM", id);
    },
    resetwishListItem({ commit }) {
      commit("RESET_wishList_ITEMS");
    },
  },
};
