const categoriesAr = {
  عطور: {
    عطور: {},
    "عطور صغيرة": {},
    "ضباب الشعر": {},
  },
  جمال: {
    "جمال كارمن": {},
  },
  دخون: {
    "دخون كارمن": {},
  },
  عود: {
    "عود كارمن": {},
  },
  بخاخ: {
    "بخاخ المنزل": {},
    "بخاخ عام": {},
  },
  زيوت: {
    "زيوت كارمن": {},
    "دهن العود": {},
  },
  صناديق: {
    "صناديق الهدايا": {},
    "صناديق العود الهندي": {},
  },
  اكسسوارات: {
    "اكسسوارات كارمن": {},
  },
  الهدايا: {
    "الهدايا الترويجية": {},
    "خدمات الزفاف": {},
  },
};
export default categoriesAr;
