<template>
  <div class="contact-us">
    <v-container fluid>
      <v-card elevation="0" class="pt-4 pb-16 mx-lg-16" min-height="700px">
        <v-row class="bg-red">
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="12"
            class="bg-grey-lighten-4 d-flex align-center justify-center"
          >
            <img src="../assets/contact-us.png" height="650px" width="120%" />
          </v-col>
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="12"
            class="bg-grey-lighten-4 d-flex align-center justify-center"
          >
            <v-card
              elevation="0"
              class="px-lg-16 mx-lg-16 bg-transparent"
              max-width="530px"
            >
              <v-card-title class="py-5 px-5 custom-title">{{
                $t("contact_us")
              }}</v-card-title>
              <v-card-text class="px-5 pb-5 custom-text">
                {{ $t("description") }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-timeline class="w-100 px-2 custom-timeline">
            <v-timeline-item dot-color="teal-darken-1" fill-dot>
              <v-card class="custom-card hover-card">
                <v-card-title class="custom-title bg-teal-darken-2">
                  <v-icon
                    class="me-4 custom-icon"
                    icon="mdi-map-marker"
                    size="large"
                  ></v-icon>
                  <h2 class="font-weight-bold text-grey-lighten-4 custom-title">
                    {{ $t("address") }}
                  </h2>
                </v-card-title>
                <v-card-text class="custom-text">
                  {{ $t("address_details") }}
                </v-card-text>
              </v-card>
            </v-timeline-item>

            <v-timeline-item dot-color="teal-lighten-1" fill-dot size="x-small">
              <v-card class="custom-card hover-card">
                <v-card-title class="custom-title bg-teal-lighten-1">
                  <v-icon
                    class="me-4 custom-icon"
                    icon="mdi-phone-in-talk"
                    size="large"
                  ></v-icon>
                  <h2 class="font-weight-bold text-grey-lighten-4 custom-title">
                    {{ $t("phone") }}
                  </h2>
                </v-card-title>
                <v-card-text class="custom-text">
                  +971 52 712 1217
                </v-card-text>
              </v-card>
            </v-timeline-item>

            <v-timeline-item dot-color="teal-lighten-2" fill-dot size="x-small">
              <v-card class="custom-card hover-card">
                <v-card-title class="custom-title bg-teal-lighten-2">
                  <v-icon
                    class="me-4 custom-icon"
                    icon="mdi-phone-sync-outline"
                    size="large"
                  ></v-icon>
                  <h2 class="font-weight-bold text-grey-lighten-4 custom-title">
                    {{ $t("complaints") }}
                  </h2>
                </v-card-title>
                <v-card-text class="custom-text">
                  +971 56 993 2225
                </v-card-text>
              </v-card>
            </v-timeline-item>

            <v-timeline-item dot-color="teal-darken-2" fill-dot>
              <v-card class="custom-card hover-card">
                <v-card-title class="custom-title bg-teal-darken-2">
                  <v-icon
                    class="me-4 custom-icon"
                    icon="mdi-email"
                    size="large"
                  ></v-icon>
                  <h2 class="font-weight-bold text-grey-lighten-4 custom-title">
                    {{ $t("email") }}
                  </h2>
                </v-card-title>
                <v-card-text class="custom-text">
                  {{ $t("general_email") }}
                </v-card-text>
                <v-card-text class="custom-text">
                  {{ $t("order_email") }}
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
};
</script>

<style scoped>
.custom-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 16px;
  font-family: "Roboto";
  padding: 24px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.custom-title {
  display: flex;
  align-items: center;
  color: white;
  padding: 16px 24px;
  margin-bottom: 16px;
  padding-left: 40px;
}

.custom-icon {
  margin-right: 16px;
  flex-shrink: 0;
  padding-left: 40px;
}

.custom-text {
  /* padding: 16px 24px; */
  padding-left: 40px;
}

/* تحسين مظهر الخط الفاصل */
.custom-timeline .v-timeline-item {
  border-left: 2px solid #e0e0e0;
  transition: border-color 0.3s ease;
}

/* تأثير التحويم على الكارت */
.hover-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* تأثير التحويم على الخط الفاصل */
.custom-timeline .v-timeline-item:hover {
  border-left-color: #008080;
}
</style>
