<template>
  <div class="home mt-4">
    <v-container fluid>
      <ControlVideo />
      <MainProduct />
      <TwoManinProducts />
      <MainCategory />
      <!-- <v-row class="bg-grey-lighten-5 py-16 my-5">
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          class="d-flex align-center justify-center flex-column"
        >
          <div class="back-ground-img px-2">
            <circleRotate
              :imgUrls="imgUrls4"
              :SquerWidth="SquerWidth"
              :postionAxis="postionAxis4"
              :SquerSize="[130, 370]"
            />
          </div>
          <a class="underline-hover my-4 text-teal">Shop Gift</a>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          class="d-flex align-center justify-center flex-column"
        >
          <BottleRotate
            :imgUrls="imgUrls2"
            :SquerWidth="2.5"
            :postionAxis="[0.0, 0.01, 0.0]"
            :SquerSize="[350, 550, 1]"
          />

          <a class="underline-hover my-4 text-teal">Shop Gift</a>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          class="d-flex align-center justify-center flex-column bg-grey-lighten-1"
        >
          <SquareRotate
            :imgUrls="imgUrls"
            :SquerWidth="SquerWidth"
            :postionAxis="postionAxis3"
            :SquerSize="[350, 350, 20]"
          />
          <a class="underline-hover my-4 text-teal">Shop Gift</a>
        </v-col>
      </v-row> -->
      <!-- <v-row class="bg-light-blue-lighten-5 py-16 my-5">

        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          class="d-flex align-center justify-center flex-column"
        >
          <div class="bg-light-blue-lighten-4 px-2">
            <circleRotate
              :imgUrls="imgUrls4"
              :SquerWidth="SquerWidth"
              :postionAxis="postionAxis4"
              :SquerSize="[130, 370]"
            />
          </div>
          <a class="underline-hover my-4 text-teal">Shop Gift</a>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          class="d-flex align-center justify-center flex-column"
        >
          <div class="back-ground-img px-2">
            <SquareRotate
              :imgUrls="imgUrls"
              :SquerWidth="SquerWidth"
              :postionAxis="postionAxis5"
              :SquerSize="[350, 350]"
            />
          </div>
          <a class="underline-hover my-4 text-teal">Shop Gift</a>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          class="d-flex align-center justify-center flex-column"
        >
          <div class="bg-light-blue-lighten-1 px-2">
            <SquareRotate
              :imgUrls="imgUrls"
              :SquerWidth="SquerWidth"
              :postionAxis="postionAxis6"
              :SquerSize="[350, 350]"
            />
          </div>
          <a class="underline-hover my-4 text-teal">Shop Gift</a>
        </v-col>
      </v-row> -->

      <v-row class="py-6">
        <CarouselItem />
      </v-row>
      <LastProduct />
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
// import SquareRotate from "@/components/UI/SquareRotate.vue";
// import circleRotate from "@/components/UI/circleRotate.vue";
// import BottleRotate from "@/components/UI/BottleRotate.vue";
import ControlVideo from "@/components/home_page/ControlVideo.vue";
import MainProduct from "@/components/home_page/MainProduct.vue";
import CarouselItem from "@/components/UI/CarouselItem.vue";
import TwoManinProducts from "@/components/home_page/TwoManinProducts.vue";
import MainCategory from "@/components/home_page/MainCategory.vue";
import LastProduct from "@/components/home_page/LastProduct.vue";

import ImgSquare from "../assets/product-squer-4.png";
import ImgSquare2 from "../assets/logo-light.png";
import ImgBottle1 from "../assets/dark-night-1.png";
import ImgBottle2 from "../assets/dark-night-2.png";

export default {
  name: "HomeView",
  components: {
    // SquareRotate,
    // circleRotate,
    // BottleRotate,
    CarouselItem,
    ControlVideo,
    MainProduct,
    TwoManinProducts,
    MainCategory,
    LastProduct,
  },
  data: () => {
    return {
      postionAxis6: [0.05, 0.0, 0.0],
      postionAxis5: [0.0, 0.0, 0.05],
      postionAxis4: [0.0, 0.02, 0.0],
      postionAxis3: [0.0, 0.08, 0.08],
      postionAxis2: [0.0, 0.05, 0.0],
      postionAxis1: [0.02, 0.02, 0.02],

      imgUrls: [
        "https://i0.wp.com/carmenperfumes.ae/wp-content/uploads/2022/04/carmen-perfumes-dubai-musk-alghazal-perfume.jpg?fit=2000%2C2000&ssl=1",
        "https://imgv3.fotor.com/images/slider-image/female-smiling-portrait-with-transparent-background.jpg",
        "https://media.istockphoto.com/id/1455690920/photo/low-angle-shot-of-a-flock-of-birds-flying-on-a-gray-sky.jpg?s=612x612&w=0&k=20&c=lIs6JePkNFOStKYidCnAGtJlbpbIUKQrZ8ojoaa-RKo=",
        "https://cdn.pixabay.com/photo/2024/05/05/05/55/goose-8740266_1280.jpg",
        ImgSquare2,
        "https://cdn.pixabay.com/photo/2023/11/09/19/36/zoo-8378189_1280.jpg",
        // "https://media.istockphoto.com/id/1455690920/photo/low-angle-shot-of-a-flock-of-birds-flying-on-a-gray-sky.jpg?s=612x612&w=0&k=20&c=lIs6JePkNFOStKYidCnAGtJlbpbIUKQrZ8ojoaa-RKo=",
      ],
      imgUrls4: [ImgSquare, "", "", "", "", ""],
      imgUrls2: [ImgBottle2, ImgBottle2, "", "", ImgBottle1, ImgBottle1],
      windowWidth: 0,
      SquerWidth: 0,
    };
  },

  beforeMount() {
    this.windowWidth = window.innerWidth;
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
    if (this.windowWidth < 1024) {
      this.SquerWidth = 2.5;
    } else {
      this.SquerWidth = 3;
    }
  },
};
</script>
