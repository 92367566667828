<template>
  <div class="loading-spinner">
    <SquareRotate
      :imgUrls="imgUrls"
      :SquerWidth="SquerWidth"
      :postionAxis="postionAxis"
      :SquerSize="[500, heightDiv, 500]"
      class="d-flex align-center justify-center"
    />
    <h2 class="text-white mb-16">Loading ...</h2>
  </div>
</template>

<script>
import SquareRotate from "./SquareRotate.vue";
import logo from "@/assets/logo.jpg";
import logoLeft from "@/assets/logo-left.jpg";
import logoRight from "@/assets/logo-right.jpg";
import logoTop from "@/assets/logo-top.jpg";

export default {
  components: {
    SquareRotate,
  },
  data: () => {
    return {
      postionAxis: [0.05, 0.05, 0.05],
      SquerWidth: 0,
      heightDiv: 0,
      imgUrls: [logo, logo, logoLeft, logoRight, logoTop, logoTop],
    };
  },

  beforeMount() {
    this.windowWidth = window.innerWidth;
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
    if (this.windowWidth < 1024) {
      this.SquerWidth = 1.8;
      this.heightDiv = 350;
    } else {
      this.SquerWidth = 2.5;
      this.heightDiv = 500;
    }
  },
};
</script>
<style scoped>
.loading-spinner {
  background-color: black;
  opacity: 0.9;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1010101010101010101;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
</style>
