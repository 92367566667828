<template>
  <div class="layout">
    <v-layout class="position-relative">
      <CartDrawer :windowWidth="windowWidth" />
      <MenueDrawer />

      <v-main
        :style="{
          paddingTop:
            $route.name === 'check-out'
              ? '0px'
              : windowWidth <= 1024
              ? '60px'
              : '135px',
        }"
        class="main-layout"
      >
        <slot></slot>
        <LoadingSpinner v-if="isLoading" />
      </v-main>

      <AppNav v-if="windowWidth > 1024 && $route.name !== 'check-out'" />

      <ResponseveNav
        v-if="windowWidth <= 1024 && $route.name !== 'check-out'"
      />

      <FixedNav v-if="windowWidth > 1024 && $route.name !== 'check-out'" />

      <AppFooter />
    </v-layout>
  </div>
</template>

<script>
import AppFooter from "./AppFooter.vue";
import AppNav from "./AppNav.vue";
import CartDrawer from "./CartDrawer.vue";
import FixedNav from "./FixedNav.vue";
import ResponseveNav from "./ResponseveNav.vue";
import MenueDrawer from "./MenueDrawer.vue";
import { mapState } from "vuex";
import theCategories from "../static-data/categories";
import LoadingSpinner from "./UI/LoadingSpinner.vue";

export default {
  components: {
    AppFooter,
    AppNav,
    CartDrawer,
    FixedNav,
    ResponseveNav,
    MenueDrawer,
    LoadingSpinner,
  },
  data() {
    return {
      drawer: false,
      windowWidth: window.innerWidth,
      showFixed: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      miniPerfumes: (state) => state.categories.miniPerfumes,
      Perfumes: (state) => state.categories.Perfumes,
      CarmenBeauty: (state) => state.categories.CarmenBeauty,
      HairMist: (state) => state.categories.HairMist,
      Dokhoon: (state) => state.categories.Dokhoon,
      OudWood: (state) => state.categories.OudWood,
      HomeSpray: (state) => state.categories.HomeSpray,
      Alloverspray: (state) => state.categories.Alloverspray,
      Oils: (state) => state.categories.Oils,
      DehnAlOud: (state) => state.categories.DehnAlOud,
      SetBoxes: (state) => state.categories.SetBoxes,
      GiftBoxes: (state) => state.categories.GiftBoxes,
      IndianAgarwoodBoxes: (state) => state.categories.IndianAgarwoodBoxes,
      Accessories: (state) => state.categories.Accessories,
      Giveaways: (state) => state.categories.Giveaways,
      WeddingServices: (state) => state.categories.WeddingServices,
    }),
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
  mounted() {
    window.addEventListener("resize", this.updateWindowWidth);

    this.isLoading = true;

    this.$store.dispatch("fetchProducts").then(() => {
      if (theCategories.en && theCategories.ar) {
        theCategories.en.PERFUMES["Mini Perfumes"] = this.miniPerfumes;
        theCategories.en.PERFUMES["Perfumes"] = this.Perfumes;
        theCategories.en.BEAUTY["Carmen Beauty"] = this.CarmenBeauty;
        theCategories.en.BEAUTY["Hair Mist"] = this.HairMist;
        theCategories.en.DOKHOON["Carmen Dokhoon"] = this.Dokhoon;
        theCategories.en.OUD["Carmen Oud Wood"] = this.OudWood;
        theCategories.en.SPRAY["Home Spray"] = this.HomeSpray;
        theCategories.en.SPRAY["All Over Spray"] = this.Alloverspray;
        theCategories.en.OILS["Carmen Oils"] = this.Oils;
        theCategories.en.OILS["Dehn Al Oud"] = this.DehnAlOud;
        theCategories.en.BOXES["Set Boxes"] = this.SetBoxes;
        theCategories.en.BOXES["Gift Boxes"] = this.GiftBoxes;
        theCategories.en.BOXES["Indian Agarwood Boxes"] =
          this.IndianAgarwoodBoxes;
        theCategories.en.ACCESSORIES["Carmen Accessories"] = this.Accessories;
        theCategories.en.GIVEAWAYS["Give Away"] = this.Giveaways;
        theCategories.en.GIVEAWAYS["Wedding Services"] = this.WeddingServices;
      }
      if (theCategories.ar) {
        // عطور section
        theCategories.ar["عطور"]["عطور صغيرة"] = this.miniPerfumes;
        theCategories.ar["عطور"]["عطور"] = this.Perfumes;

        // جمال section
        theCategories.ar["جمال"]["جمال كارمن"] = this.CarmenBeauty;
        theCategories.ar["جمال"]["ضباب الشعر"] = this.HairMist;

        // دخون section
        theCategories.ar["دخون"]["دخون كارمن"] = this.Dokhoon;

        // عود section
        theCategories.ar["عود"]["عود كارمن"] = this.OudWood;

        // بخاخ section
        theCategories.ar["بخاخ"]["بخاخ المنزل"] = this.HomeSpray;
        theCategories.ar["بخاخ"]["بخاخ عام"] = this.Alloverspray;

        // زيوت section
        theCategories.ar["زيوت"]["زيوت كارمن"] = this.Oils;
        theCategories.ar["زيوت"]["دهن العود"] = this.DehnAlOud;

        // صناديق section
        theCategories.ar["صناديق"]["صناديق الهدايا"] = this.GiftBoxes;
        theCategories.ar["صناديق"]["صناديق العود الهندي"] =
          this.IndianAgarwoodBoxes;

        // اكسسوارات section
        theCategories.ar["اكسسوارات"]["اكسسوارات كارمن"] = this.Accessories;

        // الهدايا section
        theCategories.ar["الهدايا"]["الهدايا الترويجية"] = this.Giveaways;
        theCategories.ar["الهدايا"]["خدمات الزفاف"] = this.WeddingServices;
      } else {
        console.error("theCategories.ar is not defined or is empty.");
      }
      this.isLoading = false;
    });
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 1520px) {
  .main-layout {
    max-width: 1520px !important;
    margin: auto !important;
  }
}
</style>
