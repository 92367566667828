<template>
  <v-row>
    <v-col cols="12">
      <div class="control-image">
        <img
          class="cont-image"
          src="@/assets/product4.png"
          alt="Header Image"
        />
      </div>
      <div class="d-flex flex-column align-center justify-center py-6 ms-px-16">
        <h2 class="py-2 mx-2 text-center">
          {{ $t("mothers_day_title") }}
        </h2>
        <p class="py-2 text-center">
          {{ $t("mothers_day_description") }}
        </p>
        <a
          @click="navigateToProduct"
          class="underline-hover my-4 text-teal"
          style="text-decoration: none"
        >
          {{ $t("shop_gift") }}
        </a>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { simpleEncrypt } from "@/utils/encryption";

export default {
  methods: {
    navigateToProduct() {
      const id = 35;
      const category = "Perfumes";
      const encryptedId = simpleEncrypt(id.toString());
      const encryptedCategory = simpleEncrypt(category);

      this.$router.push({
        path: "/products/ProductDetails/",
        query: {
          id: encryptedId,
          category: encryptedCategory,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.control-video {
  height: 61vh;
  position: relative;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  margin: 0px 71.6px !important;
}
.cont-video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid black;
}
.underline-hover {
  position: relative;
  cursor: pointer;
  font-size: 0.9rem;
  color: #474747;
}
.underline-hover::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 1px;
  background-color: #000; /* Adjust color as needed */
  transition: width 0.3s ease-in-out; /* Adjust transition duration and timing function as needed */
}

.underline-hover:hover::after {
  width: 100%;
}
@media (max-width: 1024px) {
  .control-video {
    height: 40vh;
    margin: 0px 41.6px !important;
  }
}
@media (max-width: 600px) {
  .control-video {
    height: 30vh;
    margin: 0px 21.6px !important;
  }
}
</style>
