<template>
  <app-layout>
    <router-view />
    <!-- <QuickView /> -->
    <!-- <PoupSearch/> -->
  </app-layout>
</template>

<script>
import AppLayout from "@/components/AppLayout.vue";
// import QuickView from "@/components/QuickView.vue";
// import PoupSearch from "./components/PoupSearch.vue";

export default {
  // inject: ["Emitter"],
  components: {
    AppLayout,
    // QuickView,
    // PoupSearch
  },
  // data: () => {
  //   return {
  //     bar: false,
  //     itemTitle: "",
  //   };
  // },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:ital,wght@0,100..900;1,100..900&display=swap");
* {
  margin: 0px !important;
  padding: 0px !important;
}
#app {
  font-family: BrilliantCutPro, Verdana, sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: Thin 100;
  font-variation-settings: "wdth" 100;
}
h2 {
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #1d1c1c;
  font-family: Brilliant Cut, Almarai, Helvetica, Arial, sans-serif;
}
P {
  line-height: 1.5;
  font-family: Fancy Cut, Almarai, Times, serif;
  letter-spacing: 1.5px;
  font-weight: 400;
  color: #737171;
  font-size: 1rem;
}
.v-rating__wrapper {
  margin-right: 5px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px !important;
  color: #616161;
}
th,
td {
  border: 2px solid #e0e0e0;
  padding: 15px;
}
.scroll-inside-items {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: #6b6d6e;
  }
  &::-webkit-scrollbar-track {
    width: 5px;
    background-color: rgb(191, 217, 230);
  }
}

@media (max-width: 700px) {
  h2 {
    font-size: 0.7rem;
  }
  P {
    font-size: 0.6rem;
  }
}
</style>
