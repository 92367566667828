// store/cart.js

export default {
  state: () => ({
    cartItems: [],
  }),
  getters: {
    cartItems: (state) => state.cartItems,
  },
  mutations: {
    ADD_ITEM(state, item) {
      let exists = false;
      for (let i = 0; i < state.cartItems.length; i++) {
        if (state.cartItems[i].id === item.id) {
          state.cartItems[i].quantity += item.quantity;
          exists = true;
          break;
        }
      }
      if (!exists) {
        state.cartItems.push(JSON.parse(JSON.stringify(item)));
      }
      localStorage.setItem("cart-item", JSON.stringify(state.cartItems));
    },
    SET_CART_ITEMS(state, items) {
      state.cartItems = items;
    },
    DELETE_ITEM(state, id) {
      for (let i = 0; i < state.cartItems.length; i++) {
        if (state.cartItems[i].id === id) {
          state.cartItems.splice(i, 1);
          break;
        }
      }
      localStorage.setItem("cart-item", JSON.stringify(state.cartItems));
    },
    RESET_ITEMS(state) {
      state.cartItems = [];
      localStorage.removeItem("cart-item");
    },
  },
  actions: {
    addItem({ commit }, item) {
      commit("ADD_ITEM", item);
    },
    getCartItem({ commit }) {
      const items = localStorage.getItem("cart-item");
      if (items) {
        commit("SET_CART_ITEMS", JSON.parse(items));
      }
    },
    deleteItem({ commit }, id) {
      commit("DELETE_ITEM", id);
    },
    resetItem({ commit }) {
      commit("RESET_ITEMS");
    },
  },
};
