<template>
  <div class="app-footer">
    <v-footer :app="true" absolute>
      <v-container fluid>
        <v-row class="bg-grey-lighten-4 my-16 py-16 px-16">
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="4"
            class="d-flex align-center justify-center flex-column"
          >
            <v-icon size="52">mdi-moped-outline</v-icon>
            <p class="my-4">{{ $t("complimentary_delivery") }}</p>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="4"
            class="d-flex align-center justify-center flex-column"
          >
            <v-icon size="52">mdi-briefcase-arrow-left-right-outline</v-icon>
            <p class="my-4">{{ $t("easy_return_or_exchange") }}</p>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="4"
            class="d-flex align-center justify-center flex-column"
          >
            <v-icon size="52">mdi-gift-outline</v-icon>
            <p class="my-4">{{ $t("free_gift_wrapping") }}</p>
          </v-col>
        </v-row>

        <v-row class="border-t-thin mx-lg-16 text-center pt-16">
          <v-col cols="12" class="d-flex justify-center align-center py-8">
            <h4>{{ $t("subscribe_to_newsletter") }}</h4>
          </v-col>
        </v-row>
        <v-row class="pb-16">
          <v-col cols="1" sm="1" lg="4" md="2"></v-col>
          <v-col cols="6" sm="6" lg="4" md="6">
            <v-text-field
              class="mb-6"
              color="black"
              :label="$t('email_label')"
              variant="underlined"
            ></v-text-field>
          </v-col>
          <v-col
            cols="4"
            sm="4"
            lg="3"
            md="4"
            class="d-flex justify-start align-center"
          >
            <v-btn color="black" class="px-4 py-2" dark>{{
              $t("subscribe_button")
            }}</v-btn>
          </v-col>
          <v-col cols="1" sm="6" lg="1" md="2"></v-col>
        </v-row>

        <v-row class="border-t-thin mx-lg-16 text-center">
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-card
              style="background-color: transparent"
              class="text-start mx-10 mt-10"
              elevation="0"
            >
              <v-card-title style="font-weight: 700">{{
                $t("customer_care")
              }}</v-card-title>
              <v-card-text class="py-4" style="color: grey">{{
                $t("about")
              }}</v-card-text>
              <v-card-text class="py-4" style="color: grey">{{
                $t("contact_us")
              }}</v-card-text>
              <v-card-text class="py-4" style="color: grey">{{
                $t("call_now")
              }}</v-card-text>
              <v-card-text class="py-4" style="color: grey">{{
                $t("faq")
              }}</v-card-text>
              <v-card-text class="py-4" style="color: grey">{{
                $t("track_your_order")
              }}</v-card-text>
              <v-card-text class="py-4" style="color: grey">{{
                $t("book_appointment")
              }}</v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-card
              style="background-color: transparent"
              class="text-start mx-10 mt-10"
              elevation="0"
            >
              <v-card-title style="font-weight: 700">{{
                $t("our_company")
              }}</v-card-title>
              <v-card-text class="py-4" style="color: grey">{{
                $t("find_a_boutique")
              }}</v-card-text>
              <v-card-text class="py-4" style="color: grey">{{
                $t("customer_service")
              }}</v-card-text>
              <v-card-text class="py-4" style="color: grey">{{
                $t("careers")
              }}</v-card-text>
              <v-card-text class="py-4" style="color: grey">{{
                $t("csr")
              }}</v-card-text>
              <v-card-text class="py-4" style="color: grey">{{
                $t("credits")
              }}</v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-card
              style="background-color: transparent"
              class="text-start mx-10 mt-10"
              elevation="0"
            >
              <v-card-title style="font-weight: 700">{{
                $t("legal_area")
              }}</v-card-title>
              <v-card-text class="py-4" style="color: grey">{{
                $t("terms_of_use")
              }}</v-card-text>
              <v-card-text class="py-4" style="color: grey">{{
                $t("privacy_policy")
              }}</v-card-text>
              <v-card-text class="py-4" style="color: grey">{{
                $t("conditions_of_sale")
              }}</v-card-text>
              <v-card-text class="py-4" style="color: grey">{{
                $t("accessibility_statement")
              }}</v-card-text>
              <v-card-text class="py-4" style="color: grey">{{
                $t("california_privacy_rights")
              }}</v-card-text>
              <v-card-text class="py-4" style="color: grey">{{
                $t("human_rights_statement")
              }}</v-card-text>
              <v-card-text class="py-4" style="color: grey">{{
                $t("do_not_sell")
              }}</v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-card
              style="background-color: transparent"
              class="text-start mx-10 mt-10"
              elevation="0"
            >
              <v-card-title style="font-weight: 700">{{
                $t("follow_us")
              }}</v-card-title>

              <div
                class="py-6 d-flex justify-space-around align-center"
                style="color: rgb(73, 73, 73)"
              >
                <a
                  href="https://www.facebook.com/carmenperfumesae"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="text-decoration: none"
                >
                  <span
                    class="d-flex align-center justify-center"
                    style="
                      margin-right: 10px;
                      height: 50px;
                      width: 50px;
                      background-color: rgb(65, 65, 65);
                      margin-top: 2px;
                      border-radius: 50%;
                    "
                    ><v-icon color="white" size="30px"
                      >mdi-facebook</v-icon
                    ></span
                  >
                </a>
                <a
                  href="https://www.instagram.com/carmen_perfumes"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="text-decoration: none"
                >
                  <span
                    class="d-flex align-center justify-center"
                    style="
                      margin-right: 10px;
                      height: 50px;
                      width: 50px;
                      background-color: rgb(65, 65, 65);
                      margin-top: 2px;
                      border-radius: 50%;
                    "
                    ><v-icon color="white" size="30px"
                      >mdi-instagram</v-icon
                    ></span
                  >
                </a>
                <a
                  href="https://www.snapchat.com/add/carmen-perfumes"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="text-decoration: none"
                >
                  <span
                    class="d-flex align-center justify-center"
                    style="
                      margin-right: 10px;
                      height: 50px;
                      width: 50px;
                      background-color: rgb(65, 65, 65);
                      margin-top: 2px;
                      border-radius: 50%;
                    "
                    ><v-icon color="white" size="30px"
                      >mdi-snapchat</v-icon
                    ></span
                  >
                </a>
                <a
                  href="https://www.tiktok.com/@carmen_perfumes"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="text-decoration: none"
                >
                  <span
                    class="d-flex align-center justify-center"
                    style="
                      margin-right: 10px;
                      height: 50px;
                      width: 50px;
                      background-color: rgb(65, 65, 65);
                      margin-top: 2px;
                      border-radius: 50%;
                    "
                    ><v-icon color="white" size="30px"
                      ><img src="@/assets/tiktok.svg" /></v-icon
                  ></span>
                </a>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="border-t-thin mx-lg-16 mt-8 py-3">
          <v-col cols="4"></v-col>
          <v-col cols="4" class="d-flex align-center justify-center">
            <div class="text-center">
              <img class="w-100" src="../assets/perfumes.svg" />
            </div>
            <div class="text-center">
              <img class="w-100" src="../assets/oils.svg" />
            </div>
            <div class="text-center">
              <img class="w-100" src="../assets/hairMist.svg" />
            </div>
            <div class="text-center">
              <img class="w-100" src="../assets/homeSpray.svg" />
            </div>
          </v-col>
          <v-col cols="4"></v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
  mounted() {
    console.log(this.$i18n.locale); // Check the current locale
  },
  watch: {
    "$i18n.locale"(newLocale) {
      console.log("Locale changed to:", newLocale);
    },
  },
};
</script>

<style lang="scss">
.app-footer {
  z-index: 1006;
  .v-footer {
    bottom: -1300px !important;
  }
}
@media (max-width: 1279px) {
  .app-footer {
    .v-footer {
      bottom: -1540px !important;
    }
  }
}
@media (max-width: 1025px) {
  .app-footer {
    .v-footer {
      bottom: -1640px !important;
    }
  }
}
@media (max-width: 959px) {
  .app-footer {
    .v-footer {
      bottom: -1840px !important;
    }
  }
}
@media (max-width: 600px) {
  .app-footer {
    .v-footer {
      bottom: -2340px !important;
    }
  }
}
</style>
