<template>
  <swiper
    :pagination="{ el: '.swiper-pagination', clickable: true }"
    :autoplay="{
      delay: 3000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    }"
    :loop="true"
    :slides-per-view="4"
    :space-between="30"
    class="w-100 py-10 px-4"
    :breakpoints="breakPoints"
    navigation
  >
    <swiper-slide class="px-3" v-for="i in 5" :key="i">
      <v-card elevation="0" class="pb-6">
        <v-img
          height="350px"
          :src="require('@/assets/product-4.png')"
          cover
        ></v-img>
        <v-card-title class="text-center py-4">
          Product By Carmen
        </v-card-title>
        <v-card-title class="text-center text-body-1">
          <a>
            Shop now
            <v-icon>mdi-chevron-right</v-icon>
          </a>
        </v-card-title>
      </v-card>
    </swiper-slide>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
    <div class="swiper-pagination"></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      breakPoints: {
        0: {
          slidesPerView: 1,
        },
        580: {
          slidesPerView: 2,
        },
        767: {
          slidesPerView: 3,
        },
        990: {
          slidesPerView: 4,
        },
      },
    };
  },
};
</script>

<style lang="scss">
.swiper-button-next,
.swiper-button-prev {
  width: 35px;
  height: 35px;
  padding: 28px !important;
  background-color: white;
  top: 40% !important;
  &::after {
    font-size: 18px !important;
    padding: 0;
    display: flex;
    text-align: center;
    justify-content: center;
    color: #b0b0b0;
    font-weight: 900;
  }
  &:hover {
    background-color: rgb(221, 221, 240) !important;
  }
}
.swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  background-color: rgb(181, 180, 180) !important;
  margin-right: 3px !important;
}
</style>
