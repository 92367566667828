import axios from "axios";

const apiUrl = "https://carmenperfumes.ae/wp-json/wc/v3";
const username = "ck_7a4bf80428b91f489c5165a4fd7281e86530b020";
const password = "cs_d39ed1eec797a2a92ab620702c485fd3236c674f";
const basicAuth = "Basic " + btoa(username + ":" + password);

export default {
  getAllProducts(perPage, page, locale) {
    const langParam = locale === "ar" ? "&lang=ar" : "&lang=en";
    const params = {
      per_page: perPage,
      page: page,
    };
    return axios.get(apiUrl + `/products?${langParam}`, {
      headers: {
        Authorization: basicAuth,
        "Content-Type": "application/json",
      },
      params: params,
    });
  },

  getProdectByCategory(categoryId, perPage, page, locale) {
    const langParam = locale === "ar" ? "&lang=ar" : "&lang=en";
    const params = {
      per_page: perPage,
      page: page,
    };
    return axios.get(apiUrl + `/products?category=${categoryId}${langParam}`, {
      headers: {
        Authorization: basicAuth,
        "Content-Type": "application/json",
      },
      params: params,
    });
  },

  async getProductById(productId, locale) {
    const langParam = locale === "ar" ? "&lang=ar" : "&lang=en";
    const response = await axios.get(
      `${apiUrl}/products/${productId}?${langParam}`,
      {
        headers: {
          Authorization: basicAuth,
        },
      }
    );
    return response;
  },
};
